import {  grey } from "@material-ui/core/colors";

const blueVariant = {
  name: '#003cc2',
  palette: {
    primary: {
      main: '#003cc2',
      contrastText: "#FFF"
    },
    secondary: {
      main: '#003cc2',
      contrastText: "#FFF"
    }
  },
  header: {
    color: '#003cc2',
    background: "#FFF",
    search: {
      color: '#003cc2'
    },
    indicator: {
      background: '#003cc2'
    }
  },
  sidebar: {
    color: "#FFF",
    background: '#003cc2',
    header: {
      color: "#FFF",
      background: '#003cc2',
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: '#003cc2',
      online: {
        background: "#FFF"
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: "#F7F9FC"
  },
  footer: {
    color:'#003cc2'
  }
};

const variants = [
  blueVariant
];

export default variants;
