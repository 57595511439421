import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import useFetch from "../api/fetcher";
import { connect } from "react-redux";
import Skeleton from '@material-ui/lab/Skeleton';
import { Link as RouterLink, withRouter, useHistory } from "react-router-dom";

import { removeToken } from '../api';

import {
  Button,
  Grid,
  Hidden,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar
} from "@material-ui/core";

import { Menu as MenuIcon, AccountCircle } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import i18n from '../i18n';

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
  border-bottom : #003cc2 solid 2px;
`;

const IconButton = styled(MuiIconButton)`
  margin: 0 ${props => props.theme.spacing(2)}px;
  color:#003cc2;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

const UserButton = styled(Button)`
  font-size: 15px;
  height: 40px;
`;

const AccountIcon = styled(AccountCircle)`
  height: 28px;
  width: 28px;
`;

function LanguageMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const { t } = useTranslation();

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  const setLanguage = (lng) => {
    i18n.changeLanguage(lng);
    closeMenu();
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Flag src={t('lng-flag')} alt={t('lng-name')} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => setLanguage('en')}>
          English
        </MenuItem>
        <MenuItem onClick={() => setLanguage('fr')}>
          Français
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

const UserMenu = withRouter(() => {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const user = useFetch('GET', '/login/identity');

  const history = useHistory();

  const { t } = useTranslation();

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const logout = () => {
    removeToken();
    history.push("/auth/login");
  };

  return (
    <React.Fragment>
      <UserButton
        color="inherit" 
        onClick={toggleMenu} 
        startIcon={<AccountIcon />}
        height={30}
        >
          {user === undefined ? <Skeleton variant="text" width={40} /> : user.AccFirstName}
      </UserButton>
      <Menu 
        open={Boolean(anchorMenu)}
        anchorEl={anchorMenu}
        onClose={closeMenu}>
        <MenuItem component={RouterLink} onClick={() => history.push("/profil")}>
          {t('profile')}
        </MenuItem>
        <MenuItem component={RouterLink} onClick={logout}>
          {t('disconnect')}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
});

const Header = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="#003cc2"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs />
          <Grid item>
            <UserMenu />
            <LanguageMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default connect()(withTheme(Header));
